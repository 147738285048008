/**
 * A React component that renders a modal for merging leads.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isOpen - Indicates whether the modal should be open or closed.
 * @param {function} props.setIsOpen - A function to set the open state of the modal.
 * @param {Object} props.leadData - The data for the current lead.
 * @returns {JSX.Element} - The rendered MergeLeadModal component.
 */
import { Dialog, Transition } from "@headlessui/react";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import SearchLeadSelect from "Components/Common/Fields/SearchLeadSelect";
import {
  formatPhoneNumber,
  isValidArray,
  showErrorMsg,
  showSuccessMsg,
} from "Modules/util";
import {
  useGetLeadQuery,
  useMergeLeadCountAndRunningDripsMutation,
  useMergeLeadMutation,
} from "Redux/Leads/lead";
import { Fragment, useEffect, useState } from "react";
import {
  mergeLeadHeader,
  getInitialValue,
  mergeLeadPayload,
  tableRowData,
} from "./mergeLeadAssets";
import { useFormik } from "formik";
import SpinnerButton from "Components/Common/Fields/SpinnerButton";
import { useSelector } from "react-redux";
import useEscapeClose from "Components/Common/Hooks/useEscapeClose";
import { ArrowsRightLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router";

const MergeLeadModal = ({ isOpen, setIsOpen, leadData }) => {
  const [leadId, setLeadId] = useState("");
  useEscapeClose(setIsOpen);
  const [mergeLeadMutation, { isLoading }] = useMergeLeadMutation();
  const [getMergeLeadData] = useMergeLeadCountAndRunningDripsMutation();
  const [lead, setLead] = useState({}); // current lead
  const [mergeLead, setMergeLead] = useState({}); // incoming lead
  const sourceList = useSelector((s) => s?.option?.source);
  const navigate = useNavigate();
  const { data: mergeLeadRes } = useGetLeadQuery(
    {
      _id: leadId,
    },
    { skip: leadId ? false : true }
  ); // Fetching lead data based on leadId using useGetLeadQuery hook

  const tableBody = tableRowData(lead, mergeLead, sourceList);

  // Function to fetch lead count and running drips data
  const getLeadCountData = async (leadId, leadType) => {
    try {
      // Fetch the merge lead data using the lead ID
      const response = await getMergeLeadData({
        leadId: leadId,
      });
      // Destructure the relevant data from the response
      const data = response?.data?.data?.mergeLeadCountAndRunningDrips || {};
      // Check if the API response indicates success
      if (data?.type === "success") {
        // Update the state with the retrieved count and drips data
        if (leadType === "current") {
          setLead((prev) => ({
            ...prev,
            ...data?.count,
            drips: data?.drips,
          }));
        }
        if (leadType === "incoming") {
          setMergeLead((prev) => ({
            ...prev,
            ...data?.count,
            drips: data?.drips,
          }));
        }
      }
    } catch (error) {
      showErrorMsg("Error fetching lead count data.");
    }
  };

  const setLeadData = (leadData) => {
    if (isValidArray(leadData?.leadResponse)) {
      getLeadCountData(leadData?.leadResponse?.[0]?._id, "current");
      setLead((prev) => ({
        ...prev,
        ...(isValidArray(leadData?.leadResponse)
          ? leadData?.leadResponse?.[0]
          : []),
        agentResponse: leadData?.agentResponse,
        categoryResponse: leadData?.categoryResponse,
        lenderResponse: leadData?.lenderResponse,
        tagResponse: leadData?.tagResponse,
      }));
    }
  };

  useEffect(() => {
    setLeadData(leadData);
  }, [leadData]);

  useEffect(() => {
    if (isValidArray(mergeLeadRes?.data?.lead?.response)) {
      getLeadCountData(
        mergeLeadRes?.data?.lead?.response?.[0]?.leadResponse?.[0]?._id,
        "incoming"
      );
      setMergeLead((prev) => ({
        ...prev,
        ...(isValidArray(mergeLeadRes?.data?.lead?.response)
          ? mergeLeadRes?.data?.lead?.response?.[0]?.leadResponse?.[0]
          : []),
        agentResponse: mergeLeadRes?.data?.lead?.response[0]?.agentResponse,
        categoryResponse:
          mergeLeadRes?.data?.lead?.response[0]?.categoryResponse,
        lenderResponse: mergeLeadRes?.data?.lead?.response[0]?.lenderResponse,
        tagResponse: mergeLeadRes?.data?.lead?.response[0]?.tagResponse,
      }));
    }
  }, [mergeLeadRes, leadId]);

  const handleMergeLead = async (values) => {
    const payload = mergeLeadPayload(values, lead, mergeLead);
    try {
      const res = await mergeLeadMutation(payload); // Execute the merge lead mutation with the provided payload
      // Check if the response indicates a successful merge
      if (res?.data?.data?.mergeLead?.type === "success") {
        if (leadData?.leadResponse?.[0]?._id !== lead?._id) {
          navigate(`?lead=${lead?._id}`);
        }
        showSuccessMsg(res.data.data.mergeLead.message);
        setIsOpen(false);
      }
      // Handle any errors returned by the merge operation
      else if (res?.data?.data?.mergeLead?.type === "error") {
        showErrorMsg(res?.data?.data?.mergeLead?.message);
      } else if (res?.data?.errors) {
        showErrorMsg("Something went wrong in lead merge");
      }
    } catch (error) {
      // Catch any unexpected errors during the merge process
      showErrorMsg("Something went wrong in lead merge");
    }
  };

  // Formik initialization for the merge lead form
  const formik = useFormik({
    initialValues: {
      // Personal details with initial values determined by the getInitialValue function
      firstName: "current",
      lastName: "current",
      registerDate: "current",
      address: getInitialValue(lead?.address, mergeLead?.address),
      zipCode: getInitialValue(lead?.zipCode, mergeLead?.zipCode),
      city: getInitialValue(lead?.city, mergeLead?.city),
      state: getInitialValue(lead?.state, mergeLead?.state),
      birthday: getInitialValue(lead?.birthDate, mergeLead?.birthDate),
      // Agent and lender information, using isValidArray to validate array fields
      agent: getInitialValue(
        isValidArray(lead?.agentResponse),
        isValidArray(mergeLead?.agentResponse)
      ),
      lender: getInitialValue(
        isValidArray(lead?.lenderResponse),
        isValidArray(mergeLead?.lenderResponse)
      ),
      // Other lead information fields
      source: getInitialValue(lead?.source, mergeLead?.source),
      leadType: getInitialValue(
        lead?.leadType && lead?.leadType !== "Not Defined",
        mergeLead?.leadType && mergeLead?.leadType !== "Not Defined"
      ),
      categories: getInitialValue(
        isValidArray(lead?.categoryResponse),
        isValidArray(mergeLead?.categoryResponse)
      ),
      description: getInitialValue(
        lead?.description,
        mergeLead?.description,
        true
      ),
      // Lead interaction counts (emails, tasks, etc.)
      EAlertCount: getInitialValue(
        lead?.EAlertCount,
        mergeLead?.EAlertCount,
        true
      ),
      noteCount: getInitialValue(lead?.noteCount, mergeLead?.noteCount, true),
      callCount: getInitialValue(lead?.callCount, mergeLead?.callCount, true),
      smsCount: getInitialValue(lead?.smsCount, mergeLead?.smsCount, true),
      taskCount: getInitialValue(lead?.taskCount, mergeLead?.taskCount, true),
      emailCount: getInitialValue(
        lead?.emailCount,
        mergeLead?.emailCount,
        true
      ),
      // Story and follow-up flags
      story:
        lead?.story || mergeLead?.story
          ? "both"
          : getInitialValue(lead?.story, mergeLead?.story, true),
      followUp: getInitialValue(lead?.isFollowUp, mergeLead?.isFollowUp),

      drips: getInitialValue(
        isValidArray(lead?.drips),
        isValidArray(mergeLead?.drips),
        true
      ),
      newAddDrips: [],
      // Current and incoming contact information
      currentPhone: isValidArray(lead?.phone) ? lead?.phone : [],
      incomingPhone: isValidArray(mergeLead?.phone) ? mergeLead?.phone : [],
      currentEmail: isValidArray(lead?.email) ? lead?.email : [],
      incomingEmail: isValidArray(mergeLead?.email) ? mergeLead?.email : [],
      currentTags: isValidArray(lead?.tagResponse)
        ? lead?.tagResponse?.map((c) => c?._id)
        : [],
      incomingTags: isValidArray(mergeLead?.tagResponse)
        ? mergeLead?.tagResponse?.map((c) => c?._id)
        : [],
      isDeleteIncomingLead: true, // Flags for handling incoming leads and merging processes
      zillowURL: getInitialValue(lead?.zillowURL, mergeLead?.zillowURL),
      contactId: getInitialValue(lead?.contactId, mergeLead?.contactId),
    },
    onSubmit: (values) => {
      handleMergeLead(values);
    },
    enableReinitialize: true,
  });

  // Effect to reset the form when the modal is opened
  useEffect(() => {
    if (isOpen) {
      formik.resetForm(); // Reset Formik form to its initial state when the modal opens
    }
  }, [isOpen]); // Adding 'formik' as a dependency ensures that any changes to the form object itself are considered

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[111]" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-6xl transform overflow-visible rounded-2xl bg-white text-left align-middle shadow-xl transition-all p-4">
                <Dialog.Title
                  as="div"
                  className="text-lg font-medium leading-6 text-gray-800 flex justify-between items-center"
                >
                  <h6 className="flex">
                    <EnvelopeIcon className="w-6 text-orange-500 mr-2" />
                    Merge Lead
                  </h6>
                  <button
                    onClick={() => setIsOpen(false)}
                    className="focus:outline-none"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 text-gray-500 hover:text-gray-700 transition duration-300"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M3.293 3.293a1 1 0 011.414 0L10 8.586l5.293-5.293a1 1 0 111.414 1.414L11.414 10l5.293 5.293a1 1 0 01-1.414 1.414L10 11.414l-5.293 5.293a1 1 0 01-1.414-1.414L8.586 10 3.293 4.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </Dialog.Title>
                <div>
                  {!leadId && (
                    <div className="w-full xs3:hidden xs3:mt-0 mt-1 inline-block">
                      <SearchLeadSelect
                        setLeadId={setLeadId}
                        label="Select the next lead"
                        formik={formik}
                        filterLeadIds={lead?._id ? [lead?._id] : []}
                      />
                      <ul className="p-3 pl-6 mt-4 list-disc text-sm border border-blue-100 rounded-lg bg-blue-50 text-blue-600 shadow-md shadow-blue-50">
                        <h4>Note</h4>
                        <li className="ml-7">
                          Merge next Lead into current Lead
                        </li>
                      </ul>
                    </div>
                  )}
                  {leadId && (
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setLead(mergeLead); // Swapping leads
                        setMergeLead(lead); // Swapping leads
                      }}
                      className="lg:hidden items-center justify-center font-normal text-sm flex mt-2 text-gray-700 focus:outline-none bg-gray-200 hover:bg-gray-300 rounded-md p-1"
                      title="Swap incoming lead with current lead"
                    >
                      <ArrowsRightLeftIcon className="w-4 text-gray-700 mr-2" />
                      Swap Leads
                    </button>
                  )}
                </div>
                <form onSubmit={formik?.handleSubmit}>
                  <div className="flex w-full mt-3">
                    <div
                      className={`${
                        leadId ? "w-full" : "xs3:w-3/5 w-full"
                      } overflow-x-auto max-w-full`}
                    >
                      <table className="table-auto mergeTable divide-y min-w-[670px] divide-gray-200 border">
                        <thead className="bg-white mergeThead">
                          <tr className="mergeTableRow">
                            {mergeLeadHeader?.map((header, index) =>
                              leadId ||
                              (header !== "Incoming Lead" &&
                                header !== "Other") ? (
                                <th
                                  key={index}
                                  scope="col"
                                  className={`${
                                    header === "Field Name" && "w-[195px]"
                                  } 
                                    ${header === "Other" && "w-[200px]"}
                                    px-6 py-3 text-left items-center text-xs font-medium text-gray-500 uppercase tracking-wider relative bg-gray-100`}
                                >
                                  {header}
                                  {header === "Current Lead" && leadId && (
                                    <button
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setLead(mergeLead); // Swapping leads
                                        setMergeLead(lead); // Swapping leads
                                      }}
                                      className="lg:flex items-center justify-center hidden text-gray-700 absolute top-[6px] right-20 focus:outline-none bg-gray-200 hover:bg-gray-300 rounded-md p-1"
                                      title="Swap incoming lead with current lead"
                                    >
                                      <ArrowsRightLeftIcon className="w-5 text-gray-700 mr-2" />
                                      Swap
                                    </button>
                                  )}
                                </th>
                              ) : null
                            )}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 block mergeTBody max-h-[75vh] w-full overflow-y-scroll">
                          {tableBody.map((row, rowIndex) => (
                            <tr
                              className="w-full table table-fixed mergeTableRow"
                              key={rowIndex}
                            >
                              <td className="px-6 py-2 md2:w-[195px] w-[140px] whitespace-nowrap text-sm font-medium text-gray-900">
                                {row.label}
                              </td>
                              <td>
                                <div className="px-6 py-2 whitespace-nowrap text-sm text-indigo-500 max-w-96 flex text-wrap overflow-y-auto">
                                  {leadId && !row?.isCheckbox && (
                                    <input
                                      id={row?.radioBtn}
                                      name={row?.radioBtn}
                                      type="radio"
                                      value="current"
                                      onChange={formik.handleChange}
                                      checked={
                                        formik.values?.[row?.radioBtn] ===
                                        "current"
                                      }
                                      title={row?.currentTitle || ""}
                                      disabled={row?.currentDisabled || false}
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-500 border-gray-300 disabled:opacity-65 disabled:cursor-not-allowed"
                                    />
                                  )}
                                  <div className="max-h-24 overflow-y-auto">
                                    {isValidArray(row?.leadValue) ? (
                                      row?.leadValue?.map((value, index) => (
                                        <span
                                          key={index}
                                          className={`mx-2 block ${
                                            (value === "No Data" ||
                                              value === "Off") &&
                                            "opacity-65"
                                          }`}
                                        >
                                          {row?.isCheckbox && leadId ? (
                                            <>
                                              <input
                                                type="checkbox"
                                                className="mr-2 my-1 focus:ring-indigo-500 h-4 w-4 text-indigo-500 border-gray-300 disabled:opacity-65"
                                                value={
                                                  row?.currentCheckBoxValue?.[
                                                    index
                                                  ]
                                                }
                                                checked={formik.values?.[
                                                  "current" + row?.radioBtn
                                                ]?.includes(
                                                  row?.currentCheckBoxValue?.[
                                                    index
                                                  ]
                                                )}
                                                onChange={(e) => {
                                                  const checked =
                                                    e.target.checked;
                                                  const phoneNumbers =
                                                    formik.values?.[
                                                      "current" + row?.radioBtn
                                                    ] || [];
                                                  const checkValue =
                                                    row?.currentCheckBoxValue?.[
                                                      index
                                                    ];
                                                  if (checked) {
                                                    // Add the checkValue if the checkbox is checked
                                                    formik.setFieldValue(
                                                      "current" + row?.radioBtn,
                                                      [
                                                        ...phoneNumbers,
                                                        checkValue,
                                                      ]
                                                    );
                                                  } else {
                                                    // Remove the checkValue if the checkbox is unchecked
                                                    formik.setFieldValue(
                                                      "current" + row?.radioBtn,
                                                      phoneNumbers?.filter(
                                                        (number) =>
                                                          number !== checkValue
                                                      )
                                                    );
                                                  }
                                                }}
                                              />
                                              {formatPhoneNumber(value)}
                                            </>
                                          ) : (
                                            <>{value || "No Data"}</>
                                          )}
                                        </span>
                                      ))
                                    ) : (
                                      <span className="mx-2 opacity-65">
                                        No Data
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </td>
                              {leadId && (
                                <>
                                  <td>
                                    <div className="px-6 py-2 whitespace-nowrap text-sm text-green-600 max-w-96 flex text-wrap overflow-y-auto">
                                      {!row?.isCheckbox && (
                                        <input
                                          id={row?.radioBtn}
                                          name={row?.radioBtn}
                                          type="radio"
                                          value="incoming"
                                          onChange={formik.handleChange}
                                          checked={
                                            formik.values?.[
                                              `${row?.radioBtn}`
                                            ] === "incoming"
                                          }
                                          className="focus:ring-green-600 h-4 w-4 text-green-600 border-gray-300 disabled:opacity-65 disabled:cursor-not-allowed"
                                          title={row?.incomingTitle || ""}
                                          disabled={
                                            row?.incomingDisabled || false
                                          }
                                        />
                                      )}
                                      <div className="max-h-24 overflow-y-auto">
                                        {isValidArray(row?.mergeValue) ? (
                                          row.mergeValue.map((value, index) => (
                                            <span
                                              key={index}
                                              className={`mx-2 block ${
                                                !value && "opacity-65"
                                              }
                                               ${
                                                 (value === "No Data" ||
                                                   value === "Off") &&
                                                 "opacity-65"
                                               }
                                              `}
                                            >
                                              {row?.isCheckbox ? (
                                                <>
                                                  <input
                                                    type="checkbox"
                                                    className="mr-2 my-1 focus:ring-green-600 h-4 w-4 text-green-600 border-gray-300 disabled:opacity-65"
                                                    value={
                                                      row
                                                        ?.incomingCheckBoxValue?.[
                                                        index
                                                      ]
                                                    }
                                                    checked={formik.values?.[
                                                      "incoming" + row?.radioBtn
                                                    ]?.includes(
                                                      row
                                                        ?.incomingCheckBoxValue?.[
                                                        index
                                                      ]
                                                    )}
                                                    onChange={(e) => {
                                                      const checked =
                                                        e.target.checked;
                                                      const phoneNumbers =
                                                        formik.values?.[
                                                          "incoming" +
                                                            row?.radioBtn
                                                        ] || [];
                                                      const checkValue =
                                                        row
                                                          ?.incomingCheckBoxValue?.[
                                                          index
                                                        ];
                                                      if (checked) {
                                                        // Add the checkValue if the checkbox is checked
                                                        formik.setFieldValue(
                                                          "incoming" +
                                                            row?.radioBtn,
                                                          [
                                                            ...phoneNumbers,
                                                            checkValue,
                                                          ]
                                                        );
                                                      } else {
                                                        // Remove the checkValue if the checkbox is unchecked
                                                        formik.setFieldValue(
                                                          "incoming" +
                                                            row?.radioBtn,
                                                          phoneNumbers?.filter(
                                                            (number) =>
                                                              number !==
                                                              checkValue
                                                          )
                                                        );
                                                      }
                                                    }}
                                                  />

                                                  {formatPhoneNumber(value)}
                                                </>
                                              ) : (
                                                <>{value || "No Data"}</>
                                              )}
                                            </span>
                                          ))
                                        ) : (
                                          <span className="mx-2 opacity-65">
                                            No Data
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td className="w-[185px]">
                                    <div>
                                      {row?.bothOption ? (
                                        <>
                                          <input
                                            id={row?.radioBtn}
                                            name={row?.radioBtn}
                                            type="radio"
                                            value="both"
                                            onChange={formik.handleChange}
                                            checked={
                                              formik.values?.[
                                                `${row?.radioBtn}`
                                              ] === "both"
                                            }
                                            title={row?.bothTitle || ""}
                                            disabled={row?.bothDisabled}
                                            className="focus:ring-green-600 h-4 w-4 text-green-600 border-gray-300 disabled:opacity-65 disabled:cursor-not-allowed"
                                          />
                                          <span
                                            className={`mx-2 text-sm text-gray-800 ${
                                              row?.bothDisabled && "opacity-65"
                                            }`}
                                          >
                                            {row?.radioBtn === "story"
                                              ? "Merge & Generate New"
                                              : "Both"}
                                          </span>
                                        </>
                                      ) : (
                                        <div className="w-[75px] text-center">
                                          -
                                        </div>
                                      )}
                                    </div>
                                  </td>
                                </>
                              )}
                            </tr>
                          ))}
                          <tr className="w-full table table-fixed mergeTableRow !border-b-0">
                            <td className="px-6 py-2 w-[195px] whitespace-nowrap text-sm font-medium text-gray-900">
                              {/* <span className="block w-2 h-2 rounded-full bg-green-500 mr-2"></span> */}
                              Running Drips List
                            </td>
                            <td>
                              <div className="px-6 py-2 whitespace-nowrap text-sm text-indigo-500 max-w-96 flex text-wrap overflow-y-auto">
                                {leadId && (
                                  <input
                                    id={"drips"}
                                    name={"drips"}
                                    type="radio"
                                    value="current"
                                    onChange={formik.handleChange}
                                    checked={formik.values?.drips === "current"}
                                    disabled={!isValidArray(lead?.drips)}
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-500 border-gray-300 disabled:opacity-65 disabled:cursor-not-allowed"
                                  />
                                )}
                                <div className="max-h-24 overflow-y-auto">
                                  {isValidArray(lead?.drips) ? (
                                    lead?.drips?.map((drip, index) => (
                                      <span
                                        key={index}
                                        className="mx-2 block"
                                        title={drip?.dripTitle}
                                      >
                                        {drip?.dripTitle}
                                      </span>
                                    ))
                                  ) : (
                                    <div className="w-[75px] text-center opacity-65">
                                      No Data
                                    </div>
                                  )}
                                </div>
                              </div>
                            </td>
                            {leadId && (
                              <>
                                <td>
                                  <div className="px-6 py-2 whitespace-nowrap text-sm text-green-600 max-w-96 flex text-wrap overflow-y-auto">
                                    {leadId && (
                                      <input
                                        id={"drips"}
                                        name={"drips"}
                                        type="radio"
                                        value="incoming"
                                        onChange={formik.handleChange}
                                        checked={
                                          formik.values?.drips === "incoming"
                                        }
                                        disabled={
                                          !isValidArray(mergeLead?.drips)
                                        }
                                        className="focus:ring-green-600 h-4 w-4 text-green-600 border-gray-300 disabled:opacity-65 disabled:cursor-not-allowed"
                                      />
                                    )}
                                    <div className="max-h-24 overflow-y-auto">
                                      {isValidArray(mergeLead?.drips) ? (
                                        mergeLead?.drips?.map((drip, index) => (
                                          <span
                                            className="mx-2 block"
                                            title={drip?.dripTitle}
                                            key={index}
                                          >
                                            {drip?.dripTitle}
                                          </span>
                                        ))
                                      ) : (
                                        <div className="w-[75px] text-center opacity-65">
                                          No Data
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td className="w-[185px]">
                                  <input
                                    id={"drips"}
                                    name={"drips"}
                                    type="radio"
                                    value="both"
                                    onChange={formik.handleChange}
                                    checked={formik.values?.drips === "both"}
                                    disabled={
                                      !isValidArray(lead?.drips) ||
                                      !isValidArray(mergeLead?.drips)
                                    }
                                    className="focus:ring-green-600 h-4 w-4 text-green-600 border-gray-300 disabled:opacity-65 disabled:cursor-not-allowed"
                                  />
                                  <span
                                    className={`mx-2 text-sm text-gray-800 ${
                                      (!isValidArray(lead?.drips) ||
                                        !isValidArray(mergeLead?.drips)) &&
                                      "opacity-65"
                                    }`}
                                  >
                                    Both
                                  </span>
                                </td>
                              </>
                            )}
                          </tr>
                          {leadId && (
                            <tr className="w-full table table-fixed mergeTableRow !border-t-0 mt-1 mb-2">
                              <td colSpan={4}>
                                <div className="flex flex-col gap-2 mx-6">
                                  <div className="bg-orange-50 px-4 py-2 rounded-md border border-orange-200">
                                    <h6 className="text-sm font-semibold text-orange-700 mr-2">
                                      Notes :
                                    </h6>
                                    <p className="text-xs text-orange-600 mt-[2px] mb-[4px]">
                                      1. If you select the current lead’s
                                      running drip, the incoming lead’s drip
                                      will be terminated, and the current lead’s
                                      drip will continue running as is.
                                      Similarly, if you select the incoming
                                      lead’s running drip, the current lead’s
                                      drip will be terminated, and the incoming
                                      lead’s drip will continue as is. If you
                                      select both options, both drips will
                                      continue running as is.
                                    </p>
                                    <p className="text-xs text-orange-600 mt-[2px]">
                                      2. If you select both options and the
                                      current and incoming drips are the same,
                                      the current lead’s drip will continue as
                                      is, and the incoming lead’s drip will be
                                      terminated.
                                    </p>
                                  </div>
                                  {leadId && (
                                    <div className="mt-2">
                                      <input
                                        type="checkbox"
                                        className="cursor-pointer appearance-none p-[9px] mr-2 rounded-sm focus:border-orange-500 checked:border-orange-500 text-orange-500 focus:ring-orange-500  disabled:opacity-50 ml-2"
                                        onClick={() =>
                                          formik.setFieldValue(
                                            "isDeleteIncomingLead",
                                            !formik.values?.isDeleteIncomingLead
                                          )
                                        }
                                        checked={
                                          formik.values?.isDeleteIncomingLead
                                        }
                                      />
                                      <label className="text-sm font-medium text-gray-700">
                                        Delete the incoming lead when a merge is
                                        completed.{" "}
                                      </label>
                                    </div>
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {!leadId && (
                      <div className="w-2/5 px-5 xs3:inline-block hidden">
                        <SearchLeadSelect
                          setLeadId={setLeadId}
                          label="Select the next lead"
                          formik={formik}
                          filterLeadIds={lead?._id ? [lead?._id] : []}
                        />
                        <ul className="p-3 pl-6 mt-4 list-disc text-sm border border-blue-100 rounded-lg bg-blue-50 text-blue-600 shadow-md shadow-blue-50">
                          <h4>Note</h4>
                          <li className="ml-7">
                            Merge next Lead into current Lead
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                  <div className="mt-3 flex flex-row-reverse sm:flex-nowrap flex-wrap space-y-2 sm:space-y-0">
                    <SpinnerButton
                      className="inline-flex w-full justify-center rounded btn-orange  px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                      title={"Merge"}
                      action={() => {}}
                      type={"submit"}
                      loading={isLoading}
                      isDisable={!leadId}
                    />
                    <button
                      type="button"
                      className=" w-full justify-center rounded bg-gray px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm bg-gray-hover focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 sm:ml-3 sm:w-auto"
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded bg-gray-50 px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={(e) => {
                        e.preventDefault();
                        formik.resetForm();
                        setLeadId(null);
                        setLead({});
                        setMergeLead({});
                        setLeadData(leadData);
                      }}
                    >
                      Clear
                    </button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MergeLeadModal;
