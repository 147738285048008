import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a EAlerts object using serverApi.injectEndpoints
const EAlert = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    GetPropertySearchesByLeadId: builder.query({
      query: (payload) => {
        return {
          method: "post",
          body: gqlBody.GetPropertySearchesByLeadId(payload),
        };
      },
      providesTags: ["EAlertByLeadId"],
    }),
    deleteSavedSearchEAlert: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.deleteSavedSearchEAlert(payload),
        };
      },
      invalidatesTags: ["HistoryByLeadId", "EAlertByLeadId"],
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useGetPropertySearchesByLeadIdQuery,
  useDeleteSavedSearchEAlertMutation,
} = EAlert;
