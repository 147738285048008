import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/outline";
import Checkbox from "Components/Common/Checkbox/Checkbox";
import React, { useState } from "react";

export default function PropertyDisclosure(props) {
  const { formik, label, list, defaultIsOpen } = props;

  const [isOpen, setIsOpen] = useState(defaultIsOpen);

  return (
    <Disclosure defaultOpen={isOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button className="flex w-full justify-between rounded-lg px-2 py-2 text-left text-sm font-medium  bg-orange-200 hover:bg-orange-300 focus:outline-none focus-visible:ring focus-visible:ring-orange-500/75">
            <label className="font-semibold block text-gray-700">{label}</label>
            <ChevronUpIcon
              className={`${
                open ? "rotate-180 transform" : ""
              } h-5 w-5 transition-transform duration-300 `}
            />
          </Disclosure.Button>
          <Disclosure.Panel>
            <div className="my-2">
              <div className="text-orange-500 space-y-1.5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2 my-3">
                {list?.map((check, index) => (
                  <Checkbox
                    key={index}
                    id={check?.variable}
                    name={check?.variable}
                    label={check?.label}
                    checked={formik?.values?.[check?.variable]}
                    onChange={(e) =>
                      formik?.setFieldValue(check?.variable, e.target.checked)
                    }
                  />
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
