export const gqlBody = {
  GetPropertySearchesByLeadId(payload) {
    return {
      query: `query GetSavedPropertySearchesByLeadId($input: getSavedPropertySearchesInput) {
          getSavedPropertySearchesByLeadId(input: $input) {
            type
            message
            code
            response {
              _id
              search
              type
              minimum
              maximum
              bedrooms
              bathrooms
              homeType
              leadId
              yearBuiltMin
              yearBuiltMax
              storiesMin
              storiesMax
              squareFeetMin
              squareFeetMax
              garageSpacesMin
              garageSpacesMax
              propertyFeaturesFilter
              communityFeaturesFilter
              exteriorFeaturesFilter
              interiorFeaturesFilter
              styleFilter
              viewFilter
              waterFrontFilter
              financingFilter
              frequency
              newListingAlert
              emailsToSend
              sentBy
              isEAlert
              subject
              polygon
              lotSizeMin
              lotSizeMax
            }
            totalCount
          }
        }`,
      variables: { input: payload },
    };
  },
  deleteSavedSearchEAlert(payload) {
    return {
      query: `mutation DeleteSavedSearchEAlert($input: DeleteSavedSearchEAlertInput!) {
        deleteSavedSearchEAlert(input: $input) {
          type
          message
          code
        }
      }`,
      variables: { input: payload },
    };
  },
};
