import { isValidArray } from "Modules/util";

export default function PropertySearchCard(props) {
  const { searchProperty } = props;

  return (
    <div className="text-xs rounded-lg p-2 m-2 shadow-md border bg-gray-100">
      <div className=" xs:flex xs:justify-between">
        <span className="text-sm font-semibold max-w-70 truncate">
          {searchProperty?.search}{" "}
          {searchProperty?.search && searchProperty?.type ? "|" : ""}{" "}
          {searchProperty?.type}
          {!(searchProperty?.search && searchProperty?.type) && (
            <span className="!font-normal opacity-55">No search query</span>
          )}
        </span>
      </div>

      <div className="flex space-x-1">
        {/* Price*/}
        {searchProperty?.minimum && (
          <span className="flex space-x-1">
            <span className="font-semibold text-gray-600">Min Price: </span>
            <span className="flex sm:flex-col font-semibold text-green-500 xs2:mt-2 sm:mt-0 sm:text-right">
              ${searchProperty?.minimum}
            </span>
          </span>
        )}
        {searchProperty?.maximum && (
          <span className="flex space-x-1">
            <span className="font-semibold text-gray-600">Max Price: </span>
            <span className="flex sm:flex-col font-semibold text-green-500 xs2:mt-2 sm:mt-0 sm:text-right">
              ${searchProperty?.maximum}
            </span>
          </span>
        )}
      </div>
      <div className="flex space-x-1">
        {/* Lot Size*/}
        {searchProperty?.lotSizeMin && (
          <span className="flex space-x-1">
            <span className="font-semibold text-gray-600">Min Lot Size: </span>
            <span className="flex sm:flex-col font-semibold xs2:mt-2 sm:mt-0 sm:text-right">
              {searchProperty?.lotSizeMin}
            </span>
          </span>
        )}
        {searchProperty?.lotSizeMax && (
          <span className="flex space-x-1">
            <span className="font-semibold text-gray-600">Max Lot Size: </span>
            <span className="flex sm:flex-col font-semibold xs2:mt-2 sm:mt-0 sm:text-right">
              {searchProperty?.lotSizeMax}
            </span>
          </span>
        )}
      </div>
      {/* Home type */}
      {isValidArray(searchProperty?.homeType) ? (
        <div>
          <span className="font-semibold text-gray-600"> Home Type :</span>{" "}
          {searchProperty?.homeType?.join(", ")}
        </div>
      ) : (
        ""
      )}
      {/* Community Features */}
      {isValidArray(searchProperty?.communityFeaturesFilter) ? (
        <div>
          <span className="font-semibold text-gray-600">
            {" "}
            Community Features :
          </span>{" "}
          {searchProperty?.communityFeaturesFilter?.join(", ")}
        </div>
      ) : (
        ""
      )}
      {/*  Interior Features : */}
      {isValidArray(searchProperty?.interiorFeaturesFilter) ? (
        <div>
          <span className="font-semibold text-gray-600">
            {" "}
            Interior Features :
          </span>{" "}
          {searchProperty?.interiorFeaturesFilter?.join(", ")}
        </div>
      ) : (
        ""
      )}
      {/*  Exterior Features */}
      {isValidArray(searchProperty?.exteriorFeaturesFilter) ? (
        <div>
          <span className="font-semibold text-gray-600">
            {" "}
            Exterior Features :
          </span>{" "}
          {searchProperty?.exteriorFeaturesFilter?.join(", ")}
        </div>
      ) : (
        ""
      )}
      {/*  Property Features */}
      {isValidArray(searchProperty?.propertyFeaturesFilter) ? (
        <div>
          <span className="font-semibold text-gray-600">
            {" "}
            Property Features :
          </span>{" "}
          {searchProperty?.propertyFeaturesFilter?.join(", ")}
        </div>
      ) : (
        ""
      )}
      {/*  Financing Features */}
      {isValidArray(searchProperty?.financingFilter) ? (
        <div>
          <span className="font-semibold text-gray-600">
            {" "}
            Financing Features :
          </span>{" "}
          {searchProperty?.financingFilter?.join(", ")}
        </div>
      ) : (
        ""
      )}
      {/*  Style Features */}
      {isValidArray(searchProperty?.styleFilter) ? (
        <div>
          <span className="font-semibold text-gray-600">Style Features :</span>{" "}
          {searchProperty?.styleFilter?.join(", ")}
        </div>
      ) : (
        ""
      )}
      {/*  View Features */}
      {isValidArray(searchProperty?.viewFilter) ? (
        <div>
          <span className="font-semibold text-gray-600">View Features :</span>{" "}
          {searchProperty?.viewFilter?.join(", ")}
        </div>
      ) : (
        ""
      )}
      {/*  WaterFront Features */}
      {isValidArray(searchProperty?.waterFrontFilter) ? (
        <div>
          <span className="font-semibold text-gray-600">
            WaterFront Features :
          </span>{" "}
          {searchProperty?.waterFrontFilter?.join(", ")}
        </div>
      ) : (
        ""
      )}

      {/* Year Built */}
      {searchProperty?.yearBuiltMin || searchProperty?.yearBuiltMax ? (
        <div className="space-x-1">
          <span className="font-semibold text-gray-600 ">Year Built :</span>
          {searchProperty?.yearBuiltMin && (
            <span>{searchProperty?.yearBuiltMin}</span>
          )}{" "}
          {searchProperty?.yearBuiltMin && searchProperty?.yearBuiltMax && (
            <b>to</b>
          )}
          {searchProperty?.yearBuiltMax && (
            <span>{searchProperty?.yearBuiltMax}</span>
          )}
        </div>
      ) : (
        ""
      )}
      {/* Stories */}
      {searchProperty?.storiesMin || searchProperty?.storiesMax ? (
        <div className="space-x-1">
          <span className="font-semibold text-gray-600">Stories :</span>
          {searchProperty?.storiesMin && (
            <span>{searchProperty?.storiesMin}</span>
          )}{" "}
          {searchProperty?.storiesMin && searchProperty?.storiesMax && (
            <b>to</b>
          )}
          {searchProperty?.storiesMax && (
            <span>{searchProperty?.storiesMax}</span>
          )}
        </div>
      ) : (
        ""
      )}
      {/* Square Feet */}
      {searchProperty?.squareFeetMin || searchProperty?.squareFeetMax ? (
        <div className="space-x-1">
          <span className="font-semibold text-gray-600">Square Feet :</span>
          {searchProperty?.squareFeetMin && (
            <span>{searchProperty?.squareFeetMin}</span>
          )}{" "}
          {searchProperty?.squareFeetMin && searchProperty?.squareFeetMax && (
            <b>to</b>
          )}
          {searchProperty?.squareFeetMax && (
            <span>{searchProperty?.squareFeetMax}</span>
          )}
        </div>
      ) : (
        ""
      )}

      {/* Garage Spaces */}
      {searchProperty?.garageSpacesMin || searchProperty?.garageSpacesMax ? (
        <div className="space-x-1">
          <span className="font-semibold text-gray-600">Garage Spaces :</span>
          {searchProperty?.garageSpacesMin && (
            <span>{searchProperty?.garageSpacesMin}</span>
          )}{" "}
          {searchProperty?.garageSpacesMin &&
            searchProperty?.garageSpacesMax && <b>to</b>}
          {searchProperty?.garageSpacesMax && (
            <span>{searchProperty?.garageSpacesMax}</span>
          )}
        </div>
      ) : (
        ""
      )}

      <div className="flex space-x-1">
        {searchProperty?.bedrooms && searchProperty?.bedrooms !== "any" ? (
          <span>
            <b>{searchProperty?.bedrooms}</b> Beds
          </span>
        ) : (
          ""
        )}
        {searchProperty?.bathrooms && searchProperty?.bathrooms !== "any" && (
          <span className="space-x-1">
            {searchProperty?.bedrooms && searchProperty?.bedrooms !== "any" && (
              <span>|</span>
            )}
            <span>
              <b>{searchProperty?.bathrooms}</b> Baths
            </span>
          </span>
        )}
      </div>
    </div>
  );
}
