import { serverApi } from "Redux/serverApi";
import { gqlBody } from "./gqlBody";

// Create a Notes object using serverApi.injectEndpoints
const Property = serverApi.injectEndpoints({
  endpoints: (builder) => ({
    GetPropertyVisits: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.GetPropertyVisitsByLeadId(payload),
        };
      },
    }),
    GetPropertySearches: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.GetPropertySearchesByLeadId(payload),
        };
      },
      providesTags: ["PropertySearch"],
    }),
    addPropertySearchEAlert: builder.mutation({
      query: (payload) => {
        return {
          method: "POST",
          body: gqlBody.addPropertySearchEAlert(payload),
        };
      },
      invalidatesTags: ["HistoryByLeadId", "PropertySearch", "EAlertByLeadId"],
    }),
  }),
  // Do not override existing endpoints
  overrideExisting: false,
});

export const {
  useGetPropertyVisitsMutation,
  useGetPropertySearchesMutation,
  useAddPropertySearchEAlertMutation,
} = Property;
