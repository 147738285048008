import trashIcon from "Assets/icons/trashIcon.svg";
import { historyModuleIcon } from "Components/Leads/assetData";
import {
  isPermission,
  parseMentions,
  showLeadDateTimeFormat,
} from "Modules/util";

export default function TodoHistoryCard(props) {
  const { history, index, userPermissions, setId, setIsDeleteHistoryModal } =
    props;

  return (
    <>
      <div
        key={index}
        className={`border m-2 p-2 shadow-md rounded-lg bg-gray-100 `}
      >
        <div className="flex justify-between">
          <h2 className="font-semibold flex items-center text-gray-600 h-6 space-x-1">
            {/* Module */}
            <span
              style={{
                filter:
                  "sepia(1) saturate(5) hue-rotate(200deg) brightness(0.9)",
              }}
            >
              {historyModuleIcon?.[history?.module]}
            </span>
            <span>{history?.module}</span>
          </h2>

          <div className="flex items-center space-x-2 ">
            <span>{showLeadDateTimeFormat(history?.date)}</span>
            {isPermission(userPermissions, "delete_history") ? (
              <div className="bg-white rounded-md p-1 w-6">
                <img
                  alt="delete-icon"
                  src={trashIcon}
                  className="h-4 w-4 hover:cursor-pointer rounded-md"
                  onClick={() => {
                    setIsDeleteHistoryModal(true);
                    setId(history?._id);
                  }}
                  title={"Delete History"}
                  isDisabled={false}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="mb-2 truncate">
          {history?.module === "Task" ? (
            <div>
              <span className="text-black">Type:</span>
              <span className="mx-1">
                {JSON.parse(history?.changes)?.type?.length
                  ? JSON.parse(history?.changes)?.type
                  : "N/A"}
              </span>
            </div>
          ) : (
            <></>
          )}
          {history?.module === "Task" ? (
            <div>
              <span className="text-black">Description:</span>
              <span className="mx-1 text-wrap">
                {JSON.parse(history?.changes)?.description?.length
                  ? JSON.parse(history?.changes)?.description
                  : "N/A"}
              </span>
            </div>
          ) : (
            <></>
          )}
          {history?.module === "Note" ? (
            <div>
              <span className="text-black">Creator:</span>
              <span className="mx-1 text-wrap">
                {JSON.parse(history?.changes)?.noteCreator?.length
                  ? JSON.parse(history?.changes)?.noteCreator
                  : "N/A"}
              </span>
            </div>
          ) : (
            <></>
          )}
          {history?.module === "Note" ? (
            <div>
              <span className="text-black">Comment:</span>
              <span className="mx-1 text-wrap">
                {JSON.parse(history?.changes)?.comment?.length
                  ? parseMentions(
                      JSON.parse(history?.changes)?.comment,
                      false,
                      true
                    )
                  : "N/A"}
              </span>
            </div>
          ) : (
            <></>
          )}
          <div>
            <span className="text-black">Note:</span>
            <span className="mx-1 text-wrap">
              {history?.note?.length ? history?.note : "N/A"}
            </span>
          </div>
          <div>
            <span className="text-black">Action by:</span>
            <span className="mx-1 font-semibold text-black">
              {history?.todoBy}
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
