import React, { useEffect, useState } from "react";
import { useGetPropertySearchesMutation } from "Redux/Leads/PropertySearch/propertySearch";
import PropertySearchCard from "./PropertySearchCard";
import { isValidArray } from "Modules/util";
import notFound from "Assets/icons/notFound.svg";
import Skeleton from "Components/Common/Fields/Skeleton";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import PropertyMail from "../PropertyMail/PropertyMail";

export default function PropertySearch({
  lead,
  canDrop,
  setIsRefetchPropertySearch = () => {},
  setIsOpenNestModal = () => {},
  setIsDeletePropertyEmailModal = () => {},
  isRefetchPropertySearch,
  setEditPropertyData = () => {},
  setIsPropertyFilterModal = () => {},
  setIsEditPropertyData = () => {},
  setPropertyEmailId = () => {},
  isPropertyFilterModal,
  isPropertyEmailId,
}) {
  // State variables
  const [propertyList, setPropertyList] = useState([]); // List of saved property searches
  const [isPropertyLoading, setIsPropertyLoading] = useState(false); // Loading state for properties
  // const [filter, setFilter] = useState({
  //   page: -1,
  //   limit: 10,
  //   leadId: lead?.leadResponse?.[0]?._id, // Lead ID to fetch properties for
  // });
  // const [totalRecords, setTotalRecords] = useState(100);
  const [getProperty, { isLoading }] = useGetPropertySearchesMutation(); // API hook to fetch saved property searches

  // Function to handle API call for fetching saved property searches
  const handleProperty = async (filterParams) => {
    try {
      setIsPropertyLoading(true);
      const response = await getProperty(filterParams);

      const savedSearches =
        response?.data?.data?.getSavedPropertySearchesByLeadId?.response;
      // const totalCount =
      //   response?.data?.data?.getSavedPropertySearchesByLeadId?.totalCount;

      if (isValidArray(savedSearches)) {
        setPropertyList((prev) => [...prev, ...savedSearches]); // Append new searches to the list
        // setTotalRecords(totalCount); // Update total record count
      }
    } catch (error) {
      console.error("Error fetching property searches:", error);
    } finally {
      setIsPropertyLoading(false);
    }
  };

  // Infinite scroll handler
  // const handleOnScroll = async (e) => {
  //   const { scrollTop, scrollHeight, clientHeight } = e.target; // Access scroll properties correctly
  //   // Check if scrolled to the bottom and more records are available
  //   if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
  //     setFilter((filter) => ({
  //       ...filter,
  //       page: filter?.page + 1,
  //     }));
  //     const currentRecord = filter?.page * filter?.limit;
  //     if (totalRecords >= currentRecord) {
  //       handleProperty({
  //         ...filter,
  //         page: filter?.page + 1,
  //         leadId: lead?.leadResponse?.[0]?._id,
  //       });
  //     }
  //   }
  // };

  // Effect to fetch data when `isRefetchPropertySearch` or `leadId` changes
  useEffect(() => {
    if (isRefetchPropertySearch && lead?.leadResponse?.[0]?._id) {
      setIsRefetchPropertySearch(false); // Reset refetch flag
      const initialFilter = {
        page: -1,
        limit: 10,
        leadId: lead?.leadResponse?.[0]?._id,
      };
      // setFilter(initialFilter); // Reset filter
      // setTotalRecords(100); // Reset total records
      setPropertyList([]); // Reset property list
      handleProperty(initialFilter); // Fetch initial searches
    }
  }, [isRefetchPropertySearch, lead?.leadResponse?.[0]?._id]);

  return (
    <Tab.Group>
      <div className={`sm:!h-[37px] flex items-center justify-between px-3 `}>
        <Tab.List className="flex flex-wrap sm:flex-nowrap items-center xs2:space-y-0 space-y-1 xs2:mt-0 mt-1">
          <Tab
            className={({ selected }) =>
              classNames(
                "px-5 py-1 text-gray-700 focus:outline-none focus:ring-0 rounded-t-lg group flex  leading-6",
                selected
                  ? "border-b-0 border-l border-r border-t border-gray-400 font-semibold rounded-t-md text-sm"
                  : "bg-white border-b border-b-gray-400  font-normal text-sm rounded-t-md hover:bg-white"
              )
            }
          >
            Property Mail & Saved Search
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "px-5 py-1 text-gray-700 focus:outline-none focus:ring-0 rounded-t-lg group flex leading-6",
                selected
                  ? "border-b-0 border-l border-r border-t border-gray-400 font-semibold rounded-t-md text-sm"
                  : "bg-white border-b border-b-gray-400  font-normal text-sm rounded-t-md hover:bg-white"
              )
            }
            onClick={() => setIsRefetchPropertySearch(true)}
          >
            Property Searches
          </Tab>
        </Tab.List>
      </div>
      <Tab.Panels>
        <Tab.Panel>
          {/* Property Visit Body */}
          <PropertyMail
            lead={lead}
            setIsOpenNestModal={setIsOpenNestModal}
            setIsEditPropertyData={setIsEditPropertyData}
            setIsDeletePropertyEmailModal={setIsDeletePropertyEmailModal}
            setEditPropertyData={setEditPropertyData}
            setIsPropertyFilterModal={setIsPropertyFilterModal}
            isPropertyFilterModal={isPropertyFilterModal}
            setPropertyEmailId={setPropertyEmailId}
          />
        </Tab.Panel>
        <Tab.Panel>
          {/* Property Search Body */}
          <div
            className={`leadDeatils-card-body !h-[350px] rounded-b-2xl overflow-y-auto py-0.5 px-1 `}
            // onScroll={(e) => handleOnScroll(e)} // Attach scroll event handler
          >
            {isValidArray(
              propertyList?.filter((search) => !search?.isEAlert)
            ) ? (
              propertyList
                ?.filter((search) => !search?.isEAlert)
                ?.map((searchProperty, index) => (
                  <PropertySearchCard
                    key={index + 1}
                    searchProperty={searchProperty}
                  />
                ))
            ) : (
              <>
                {isPropertyLoading ? (
                  <Skeleton className="my-1" height={"60px"} counter={3} />
                ) : (
                  <>
                    <div className="flex h-full justify-center items-center text-gray-600 text-xs">
                      <span>No property search available.</span>
                    </div>
                  </>
                )}
              </>
            )}
            {/* Render loader at the bottom during data fetching */}
            {isPropertyLoading && (
              <Skeleton className="my-1" height={"60px"} counter={3} />
            )}
          </div>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}
