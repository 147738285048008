import { fetchFromRestApi } from "Axios/fetchFromRestApi";
import { getHighlightedText, isValidArray } from "Modules/util";
import { useEffect, useRef, useState } from "react";

export default function PropertySearchBar(props) {
  const { setSearchValue, searchValue } = props;
  const [search, setSearch] = useState(searchValue?.search ?? "");
  const [propertyList, setPropertyList] = useState([]);
  const [isFocused, setIsFocused] = useState(false); // Track focus state
  const inputRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0); // State to keep track of the active dropdown item

  // Fetch Data from API
  const fetchData = async () => {
    try {
      const data = await fetchFromRestApi(`/api/property/city-search?search=${search}`);
      setProperty(data?.data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // Call AIP on Change of Search Value
  useEffect(() => {
    fetchData();
    if (!search) {
      setSearchValue({ search: "" });
    }
  }, [search]);

  // Set Property
  function setProperty(data) {
    if (isValidArray(data)) {
      setPropertyList(data);
    }
  }

  // Add Searched Property
  function selectProperty(newValue) {
    if (newValue) {
      setSearch(newValue ?? "");
    }
  }

  useEffect(() => {
    if (search?.length) {
      window.addEventListener("keydown", handleKeyDown); // Add event listener to window
      return () => {
        window.removeEventListener("keydown", handleKeyDown); // Clean up event listener on unmount
      };
    }
  }, [activeIndex, search, propertyList]); // Add dependencies

  // Function to handle keyboard navigation and selection
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault(); // Prevent default scrolling behavior
      setActiveIndex((prevIndex) =>
        prevIndex < propertyList?.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault(); // Prevent default scrolling behavior
      setActiveIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === "Enter" && activeIndex !== -1) {
      selectProperty(propertyList?.[activeIndex]?.display);
      const updatedSearch =
        propertyList?.[activeIndex]?.display?.replace(", CA", "") ?? "";
      let value = {
        search: updatedSearch,
        type: propertyList?.[activeIndex]?.type ?? "",
      };

      setSearchValue(value);
      setIsFocused(false);
      if (inputRef.current) {
        inputRef.current?.blur(); // Programmatically remove focus
      }
    }
  };

  return (
    <div>
      <input
        ref={inputRef}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setTimeout(() => setIsFocused(false), 300);
        }}
        type="text"
        className="px-2 py-[5px] w-[450px] border text-sm border-gray-300 focus:border-0 rounded-md focus:outline-none focus:ring-2 ring-gray-300 placeholder:text-gray-400 focus:ring-inset focus:ring-[#475361] sm:text-sm sm:leading-6 placeholder:text-sm"
        onChange={(e) => setSearch(e?.target?.value)}
        value={search}
      />
      <div>
        {isValidArray(propertyList) && search?.length > 2 && isFocused ? (
          <div className="absolute bg-gray-100 mt-0.5 rounded w-[450px] z-10 max-h-[300px] overflow-y-auto">
            <ul
              className=" overflow-y-auto cursor-pointer"
              onScroll={(e) => e.preventDefault()}
            >
              {propertyList?.map((property, index) => (
                <li
                  key={index}
                  id={`property-${index}`} // Add an id for scrolling
                  onClick={() => {
                    selectProperty(propertyList?.[index]?.display);
                    const updatedSearch =
                      propertyList?.[activeIndex]?.display?.replace(
                        ", CA",
                        ""
                      ) ?? "";
                    let value = {
                      search: updatedSearch,
                      type: propertyList?.[index]?.type ?? "",
                    };
                    setSearchValue(value);
                    setIsFocused(false);
                    if (inputRef.current) {
                      inputRef.current?.blur(); // Programmatically remove focus
                    }
                  }}
                  onMouseEnter={() => setActiveIndex(index)}
                  className={`border text-xs min-h-8 p-2 ${
                    activeIndex === index ? "bg-orange-200" : "bg-orange-50"
                  } hover:bg-orange-200`}
                  onKeyDown={(e) => handleKeyDown(e)} // Handle key events here
                  tabIndex={0} // Allow the li element to be focusable
                  onBlur={() => setIsFocused(false)}
                >
                  <div className="space-x-1 mr-2">
                    <span className=" text-gray-600 ">
                      {getHighlightedText(property?.display, search)}
                    </span>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          !isValidArray(propertyList) &&
          search?.trim()?.length > 2 &&
          isFocused && (
            <div className="absolute w-[450px] bg-gray-100 mt-0.5 rounded border-2 p-2 text-xs">
              No Results
            </div>
          )
        )}
      </div>
    </div>
  );
}
