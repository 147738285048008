export const gqlBody = {
  GetPropertyVisitsByLeadId(payload) {
    return {
      query: `query GetPropertyVisitsByLeadId($input: GetPropertyVisitsInput) {
          getPropertyVisitsByLeadId(input: $input) {
            type
            message
            code
            properties {
              listingType
              price
              address
              mapRedirect
              bedroom
              bathroom
              image
              leadId
              userId
              date
              isFromZillow
              totalViews
              isWishListed
              _id
              LotSizeAcres
            }
            totalCount
          }
        }`,
      variables: { input: payload },
    };
  },
  GetPropertySearchesByLeadId(payload) {
    return {
      query: `query GetSavedPropertySearchesByLeadId($input: getSavedPropertySearchesInput) {
          getSavedPropertySearchesByLeadId(input: $input) {
            type
            message
            code
            response {
              _id
              search
              type
              minimum
              maximum
              bedrooms
              bathrooms
              homeType
              leadId
              yearBuiltMin
              yearBuiltMax
              storiesMin
              storiesMax
              squareFeetMin
              squareFeetMax
              garageSpacesMin
              garageSpacesMax
              propertyFeaturesFilter
              communityFeaturesFilter
              exteriorFeaturesFilter
              interiorFeaturesFilter
              styleFilter
              viewFilter
              waterFrontFilter
              financingFilter
              frequency
              newListingAlert
              emailsToSend
              sentBy
              isEAlert
              subject
              polygon
              lotSizeMin
              lotSizeMax
            }
            totalCount
          }
        }`,
      variables: { input: payload },
    };
  },
  addPropertySearchEAlert(payload) {
    return {
      query: `mutation AddPropertySearchEAlert($input: AddPropertySearchEAlertInput!) {
      addPropertySearchEAlert(input: $input) {
        type
        message
        code
      }
    }`,
      variables: { input: payload },
    };
  },
};
