import { leadTypeShort } from "Components/Common/data";
import { useEffect, useRef, useState } from "react";

export default function LeadTypeDropdown(props) {
  const { handleSubmit, leadTypeInfo } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const popupRef = useRef(null);

  useEffect(() => {
    setSelectedOption({ value: leadTypeInfo, label: leadTypeInfo });
  }, [leadTypeInfo]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false); // Close dropdown after selection
    handleSubmit({ leadType: option?.value }); // Call the handleSubmit function with the selected option
  };

  useEffect(() => {
    // Add event listener for clicks outside
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle click outside to close input box
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <div ref={popupRef} className="relative">
        <button
          className="text-white text-sm max-w-max"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          {selectedOption?.value}
          <span className="float-right">{isOpen ? "▲" : "▼"}</span>
        </button>

        {isOpen ? (
          <ul
            className="absolute mt-1 text-nowrap bg-white border shadow-xl text-xs z-40 rounded-md max-h-[350px] overflow-y-auto"
            onClick={(e) => {
              e.stopPropagation(); // Prevent event from propagating up
            }}
          >
            {leadTypeShort
              ?.filter((type) => type?.value !== leadTypeInfo)
              ?.map((type) => (
                <li
                  key={type?.value}
                  className="text-sm py-1 px-2 hover:bg-blue-100 hover:cursor-pointer border-b flex space-x-2"
                  onClick={() => handleSelect(type)}
                >
                  <span>{type?.label}</span>
                  <span>{type?.value}</span>
                </li>
              ))}
          </ul>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
