import { UserIcon } from "@heroicons/react/24/solid";
import { pinIcon } from "Assets/icons/SvgIcons";
import editIcon from "Assets/icons/editIcon.svg";
import moveIcon from "Assets/icons/moveIcon.svg";
import trashIcon from "Assets/icons/trashIcon.svg";
import SpinnerButton from "Components/Common/Buttons/SpinnerButton";
import Skeleton from "Components/Common/Fields/Skeleton";
import ConfirmationModal from "Components/Common/Modals/ConfirmationModal";
import EditDescriptionAndNotesModal from "Components/Leads/Modal/DescriptionAndNotesModal/EditDescriptionAndNotesModal";
import { isUniqueMentionsArray } from "Components/Leads/assetData";
import {
  isPermission,
  isValidArray,
  isValidObject,
  parseMentions,
  showErrorMsg,
  showLeadShortDateTimeFormat,
  showSuccessMsg
} from "Modules/util";
import {
  useAddNoteMutation,
  useDeleteNoteMutation,
  useGetNoteByLeadIdQuery,
  useSwapDescriptionNoteMutation,
} from "Redux/Leads/Notes/notes";
import { useGetAllUsersQuery } from "Redux/user/user";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { Mention, MentionsInput } from "react-mentions";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import mentionStyle from "../mentionStyle";

export default function Note({
  lead,
  isEditPermisstion,
  notesContainerRef,
  drag,
  canDrop,
  isDragging,
  setIsOpenNestModal,
}) {
  const { data: getAllUsers } = useGetAllUsersQuery({
    search: "",
    page: -1,
  }); // Fetching user data using a query hook
  const [addNote] = useAddNoteMutation(); // Mutation hook for adding a note
  const [deleteNote] = useDeleteNoteMutation(); // Mutation hook for deleting a note
  const [isDeleteNoteModal, setIsDeleteNoteModal] = useState(false); // State for delete confirmation modal
  const [noteId, setNoteId] = useState(); // State for set delete id
  const userPermissions = useSelector((state) => state?.user?.permission);
  const loginUser = useSelector((state) => state?.user?.currentUser); //getting current logged in user
  const noteContainerRef = useRef(null); // Refs for the note container
  // State variables for mention data and saving/loading state
  const [mentionData, setMentionData] = useState([]);
  const [saveLoading, setIsSaveLoading] = useState(false);
  const [mentionUser, setMentionUser] = useState([]);
  const [isEditNoteModal, setIsEditNoteModal] = useState(false);
  const [notesList, setNotesList] = useState([]);
  const [comment, setComment] = useState([]);
  const [isDescription, setIsDescription] = useState(false);
  const leadId = lead?.leadResponse?.[0]?._id;

  const [SwapDescriptionNote] = useSwapDescriptionNoteMutation();

  // lead wise note list api
  const {
    data: getNotesByLeadId,
    isLoading: isNotesByLeadIdLoading,
    refetch: notesListRefetch,
  } = useGetNoteByLeadIdQuery(
    {
      _id: leadId,
    },
    { skip: leadId ? false : true }
  );

  useEffect(() => {
    let notesListData = getNotesByLeadId?.data?.getNoteByLeadId?.noteResponse;
    if (!isNotesByLeadIdLoading) {
      if (isValidArray(notesListData)) {
        const description = notesListData?.filter(
          (note) => note?.isDescription
        );
        const notes = notesListData?.filter((note) => !note?.isDescription);
        setNotesList([...description, ...notes]);
      } else {
        setNotesList([]);
      }
    }
  }, [getNotesByLeadId]);

  useEffect(() => {
    setIsOpenNestModal((prev) => ({
      ...prev,
      deleteNoteModal: isDeleteNoteModal,
    }));
  }, [isDeleteNoteModal]);

  // Formik for handling form state, validation, and submission
  const formik = useFormik({
    initialValues: {
      leadId: lead?.leadResponse ? lead?.leadResponse[0]?._id : "",
      comment: "",
      noteCreator: `${loginUser?.firstName} ${loginUser?.lastName}`,
    },
    validationSchema: Yup.object({
      comment: Yup.string().required("Note is required"),
    }),
    onSubmit: async (values) => {
      // Check for unique mentions before submitting the note
      if (!isUniqueMentionsArray(mentionUser)) {
        formik.setFieldError("comment", "Enter only unique mentions.");
        return;
      }
      try {
        setIsSaveLoading(true);
        const res = await addNote(values);
        const responseData = res?.data?.data?.addNote;
        setIsSaveLoading(false);
        // Handle response from the mutation
        if (responseData) {
          if (responseData?.type === "success") {
            showSuccessMsg(responseData?.message);
            notesListRefetch();
            formik.resetForm();
            setMentionUser([]);
          } else if (responseData?.type === "error") {
            showErrorMsg(responseData?.message);
          }
        }
      } catch (error) {
        showErrorMsg("Add Note error");
        setIsSaveLoading(false);
      }
    },
    enableReinitialize: true,
  });

  // Effect to fetch mention data when users are available
  useEffect(() => {
    if (isValidArray(getAllUsers?.data?.users?.response)) {
      setMentionData(
        getAllUsers?.data?.users?.response?.map((user) => {
          return {
            id: user?._id,
            display: user?.name,
          };
        })
      );
    }
  }, [getAllUsers]);

  // Effect to update mention data when users are mentioned
  useEffect(() => {
    if (isValidArray(getAllUsers?.data?.users?.response)) {
      let mentionUserId = mentionUser?.map((user) => user?.id);
      let user = getAllUsers?.data?.users?.response?.map((user) => {
        return {
          id: user?._id,
          display: user?.name,
        };
      });
      setMentionData(() =>
        user?.filter((user) => !mentionUserId?.includes(user?.id))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mentionUser]);

  // Effect to scroll to the bottom when notes are updated
  useEffect(() => {
    if (noteContainerRef?.current) {
      noteContainerRef.current.scrollTop =
        noteContainerRef.current.scrollHeight;
    }
  }, [notesList]);

  //Handle delete Note
  function handleDeleteNote() {
    let payload = {
      leadId: leadId,
      noteId: noteId,
    };
    //Delete note Mutation call
    deleteNote(payload)
      .then((response) => {
        if (response?.data?.data?.deleteNote?.type === "success") {
          showSuccessMsg(response?.data?.data?.deleteNote?.message);
        } else if (response?.data?.data?.deleteNote?.type === "error") {
          showErrorMsg(response?.data?.data?.deleteNote?.message);
        }
      })
      .catch((error) => showErrorMsg("Error occurred while Deleting Note."));
    setIsDeleteNoteModal(false);
  }

  // Effect for reset noteId & comment
  useEffect(() => {
    if (!isEditNoteModal) {
      setComment(null);
      setNoteId(null);
    }
  }, [isEditNoteModal]);

  //Handle Swap Note Into Description
  async function handleSwapNoteIntoDescription(noteId) {
    let payload = {
      leadId: leadId,
      noteId: noteId,
    };
    // Swap note Mutation call
    const res = await SwapDescriptionNote(payload);
    const swapNoteIntoDescriptionResponse =
      res?.data?.data?.swapDescriptionNote;
    try {
      if (isValidObject(swapNoteIntoDescriptionResponse)) {
        const { type, message } = swapNoteIntoDescriptionResponse;
        if (type === "success") {
          showSuccessMsg(message);
        } else if (type === "error") {
          showErrorMsg(message);
        }
      } else {
        showErrorMsg("Error occurred while swap note");
      }
    } catch (error) {
      showErrorMsg("Error occurred while swap note");
    }
  }

  return (
    <div className="!w-full">
      <ConfirmationModal
        isModalOpen={isDeleteNoteModal}
        setModalOpen={setIsDeleteNoteModal}
        handleAction={() => handleDeleteNote()}
      />
      <EditDescriptionAndNotesModal
        isOpen={isEditNoteModal}
        setIsOpen={setIsEditNoteModal}
        isDescription={isDescription}
        noteId={noteId}
        comment={comment}
      />
      <div
        className="w-full sm:!min-w-[475px] shadow-md rounded-2xl"
        style={{
          opacity: isDragging ? 0.4 : 1,
        }}
      >
        <div
          className={`bg-emerald-400 !h-[40px] rounded-t-2xl flex items-center justify-between px-4 ${
            canDrop && "!bg-emerald-300 opacity-85"
          }`}
        >
          <div className="space-x-2 flex items-center ">
            <div className="flex">
              <div className="bg-white w-0.5"></div>
              <div className="text-white pl-[7px] font-medium">Notes</div>
            </div>
          </div>
          <div
            className="bg-emerald-600 rounded-md flex items-center p-1.5 h-7 w-7 cursor-move"
            ref={drag}
          >
            <img
              alt="edit-icon"
              src={moveIcon}
              className="h-4 w-4 rounded-md filter invert brightness-0"
              title={"Move Segment"}
            />
          </div>
        </div>
        <div
          className={`leadDeatils-card-body !h-[390px] rounded-b-2xl !text-xs py-2 px-1 ${
            canDrop && "!bg-emerald-300 opacity-85"
          }`}
        >
          <div
            className="!h-[280px] overflow-y-auto scrollbar-emerald"
            ref={notesContainerRef}
          >
            {isNotesByLeadIdLoading ? (
              <Skeleton counter={3} height={"60px"} className="my-3" />
            ) : isValidArray(notesList) ? (
              notesList?.map((note) => (
                <div
                  key={note?._id}
                  className="rounded-lg p-2 m-2 shadow-md border bg-gray-100"
                >
                  <div className="flex justify-between">
                    <div className="font-semibold text-gray-600 text-md mb-1 mr-2">
                      <span className=" flex items-center">
                        <label className="flex">
                          {" "}
                          <UserIcon className="!w-4 !h-4 flex-shrink-0 text-emerald-600" />
                        </label>
                        <label className="text-black">
                          {note?.noteCreator}{" "}
                          {note?.isDescription && "(Description)"}
                        </label>
                      </span>
                    </div>
                    <div className="flex items-center text-gray-500 space-x-1.5">
                      {!note?.isDescription && (
                        <div className="bg-white rounded-md p-0.5 w-6 text-emerald-700">
                          <span
                            className="flex items-center justify-center hover:cursor-pointer"
                            title="Pin to Description"
                            onClick={() =>
                              handleSwapNoteIntoDescription(note?._id)
                            }
                          >
                            {pinIcon}
                          </span>
                        </div>
                      )}
                      <div className="bg-white rounded-md p-1 ">
                        <img
                          alt="edit-icon"
                          src={editIcon}
                          className={`h-4 w-4 rounded-md hover:cursor-pointer  `}
                          onClick={() => {
                            setIsDescription(() =>
                              note?.isDescription ? true : false
                            );
                            setIsEditNoteModal(true);
                            setNoteId(note?._id);
                            setComment(note?.comment);
                          }}
                          style={{
                            filter:
                              "brightness(0.9) contrast(0.9) saturate(0.6) hue-rotate(105deg)",
                          }}
                          title={"Edit note"}
                          isDisabled={false}
                        />
                      </div>
                      {isPermission(userPermissions, "delete_note") ? (
                        <div className="bg-white rounded-md p-1 w-6">
                          <img
                            alt="delete-icon"
                            src={trashIcon}
                            className="h-4 w-4 hover:cursor-pointer rounded-md"
                            onClick={() => {
                              setIsDeleteNoteModal(true);
                              setNoteId(note?._id);
                            }}
                            title={"Delete Note"}
                            isDisabled={false}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div>
                      <b>Created on:</b>{" "}
                      {showLeadShortDateTimeFormat(note?.createdAt)}
                    </div>
                    {showLeadShortDateTimeFormat(note?.createdAt) !==
                      showLeadShortDateTimeFormat(note?.updatedAt) && (
                      <div>
                        <b>Edited on:</b>{" "}
                        {showLeadShortDateTimeFormat(note?.updatedAt)}
                      </div>
                    )}
                  </div>
                  <div className="text-gray-800 mt-1">
                    {parseMentions(note?.comment, true)}
                  </div>
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center text-gray-600 h-full">
                <span>No notes history available.</span>
              </div>
            )}
          </div>
          {isEditPermisstion ? (
            <form
              onSubmit={formik.handleSubmit}
              className="pt-2 border-t text-xs border-gray-200 rounded-lg "
            >
              <div className="flex pt-1 ps-2">
                <div className="w-full">
                  <MentionsInput
                    className="mentions border border-gray-300 rounded-md  focus:outline-none focus:border-emerald-500 focus:text-black "
                    value={formik.values.comment}
                    onChange={(e, newValue, newPlainTextValue, mentions) => {
                      setMentionUser(mentions);
                      formik.setFieldValue("comment", e?.target?.value);
                    }}
                    placeholder="Enter Note"
                    style={mentionStyle()}
                  >
                    <Mention
                      className="mentions__mention mentions__mention"
                      data={mentionData}
                      style={{
                        color: "#15803d",
                        textDecoration: "underline",
                        padding: "0px 0px",
                      }}
                      displayTransform={(id, display) => `@${display}`}
                    />
                  </MentionsInput>
                  {formik?.touched?.comment && formik?.errors?.comment ? (
                    <div className="text-red-500 text-sm mt-1">
                      {formik?.errors?.comment}
                    </div>
                  ) : null}
                </div>
                <div className="w-16 h-8 ml-3 my-auto">
                  <SpinnerButton
                    type="submit"
                    label="Add"
                    title={
                      !formik?.values?.comment
                        ? "Please Enter Note"
                        : "Add Note"
                    }
                    className="btn-notes rounded-lg text-sm font-medium disabled:opacity-75 disabled:cursor-not-allowed"
                    isLoading={saveLoading}
                    isDisable={
                      !formik?.values?.comment || saveLoading ? true : false
                    }
                  />
                </div>
              </div>
            </form>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
