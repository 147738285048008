export const TimeZoneData = [
  "America/New_York",
  "America/Los_Angeles",
  "America/Detroit",
  "America/Kentucky/Louisville",
  "America/Kentucky/Monticello",
  "America/Indiana/Indianapolis",
  "America/Indiana/Vincennes",
  "America/Indiana/Winamac",
  "America/Indiana/Marengo",
  "America/Indiana/Petersburg",
  "America/Indiana/Vevay",
  "America/Chicago",
  "America/Indiana/Tell_City",
  "America/Indiana/Knox",
  "America/Menominee",
  "America/North_Dakota/Center",
  "America/North_Dakota/New_Salem",
  "America/North_Dakota/Beulah",
  "America/Denver",
  "America/Boise",
  "America/Phoenix",
  "America/Anchorage",
  "America/Juneau",
  "America/Sitka",
  "America/Metlakatla",
  "America/Yakutat",
  "America/Nome",
  "America/Adak",
  "Pacific/Honolulu",
];
export const pageTitles = {
  dashboard: "Dashboard",
  leadList: "Leads List",
  leadDetails: "Lead Details",
  taskList: "Tasks List",
  usersList: "Users List",
  drips: "Drips",
  addDrip: "Add Drip",
  editDrip: "Edit Drip",
  role: "Role",
  emailTemplate: "Email Template",
  smsTemplate: "SMS Template",
  settings: "Settings",
  pendingMessages: "Pending Messages",
  reports: "Reports",
  activity: "Activity",
};

export const sentAfterOptions = [
  { value: "minutes", label: "Minutes" },
  { value: "hours", label: "Hour" },
  { value: "days", label: "Days" },
  { value: "weeks", label: "Weeks" },
  { value: "months", label: "Months" },
  { value: "years", label: "Years" },
];
export const nodeTimeOptions = [
  { value: "minutes", label: "Minutes" },
  { value: "hours", label: "Hour" },
  { value: "days", label: "Days" },
  { value: "weeks", label: "Weeks" },
  { value: "months", label: "Months" },
  { value: "years", label: "Years" },
];

export const sortingOptions = [
  { value: "desc", label: "Newest to Oldest" },
  { value: "asc", label: "Oldest to Newest" },
];

export const dropDownMonths = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

export const emailReportStatus = [
  { value: "all", label: "All" },
  { value: "delivered", label: "Delivered" },
  { value: "not delivered", label: "Not Delivered" },
];
// export const emailReportStatus = [
//   { value: "all", label: "All" },
//   { value: "processed", label: "Processed" },
//   { value: "dropped", label: "Dropped" },
//   { value: "deferred", label: "Deferred" },
//   { value: "bounce", label: "Bounced" },
//   { value: "delivered", label: "Delivered" },
// ]
export const emailTypeOptions = [
  { value: "all", label: "All" },
  { value: "sent", label: "Sent" },
  { value: "received", label: "Received" },
];
export const moduleListOptions = [
  { value: "all", label: "All" },
  { value: "auth", label: "Auth" },
  { value: "user", label: "User" },
  { value: "roles", label: "Roles" },
  { value: "configurations", label: "Configurations" },
  { value: "leads", label: "Leads" },
  { value: "category", label: "Category" },
  { value: "tag", label: "Tag" },
  { value: "SMS - template", label: "SMS Template" },
  { value: "Email - template", label: "Email Template" },
  { value: "task", label: "Task" },
  { value: "notes", label: "Note" },
  { value: "drip", label: "Drip" },
  { value: "notification", label: "Notification" },
  { value: "custom - template", label: "Custom Template" },
  { value: "E - alert", label: "E-Alert" },
  { value: "pending - messages", label: "Pending Messages" },
];

export const activityLogPermissionOptions = [
  { value: "all", label: "All" },
  { value: "none", label: "None" },
  { value: "own", label: "Own" },
];
