export const gqlBody = {
  getNoteByLeadId(payload) {
    return {
      query: `query GetNoteByLeadId($input: inputSingleLead) {
        getNoteByLeadId(input: $input) {
          type
          message
          code
          noteResponse {
            _id
            noteCreator
            comment
            date
            refUsers
            isDescription
            createdAt
            updatedAt
          }
        }
      }`,
      variables: { input: payload },
    };
  },
  addNote(payload) {
    return {
      query: `mutation AddNote($input: AddNoteInput) {
        addNote(input: $input) {
          code
          message
          type
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  deleteNote(payload) {
    return {
      query: `mutation Mutation($input: DeleteNoteInput) {
        deleteNote(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  editNote(payload) {
    return {
      query: `mutation EditNote($input: EditNoteInput) {
        editNote(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
  swapDescriptionNote(payload) {
    return {
      query: `mutation SwapDescriptionNote($input: SwapDescriptionNoteInput!) {
        swapDescriptionNote(input: $input) {
          type
          message
          code
        }
      }`,
      variables: {
        input: payload,
      },
    };
  },
};
