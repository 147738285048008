import axios from "axios";

export const fetchFromRestApi = async (
  endpoint,
  method = "GET",
  data = null,
  headers = {}
) => {
  try {
    const response = await axios({
      url: `${process.env.REACT_APP_PROPERTY_LISTING_API}${endpoint}`, // Full URL of the API
      method: method.toUpperCase(), // Ensure HTTP method is uppercase
      data, // Payload for POST/PUT
      headers, // Optional headers
    });
    return response?.data; // Return only the data part of the response
  } catch (error) {
    // Handle and log the error
    console.error("Error calling REST API:", error);

    // Optionally, rethrow the error or handle specific error scenarios
    throw error;
  }
};
