import GoogleMapReact from "google-map-react";
import { isValidArray } from "Modules/util";
import { useEffect, useRef, useState } from "react";

const MapComponent = (props) => {
  const {
    setPolygoneValues,
    polygonValues,
    isEditPropertyData,
    editPropertyData,
  } = props;
  const [isDrawingMode, setDrawingMode] = useState(false); // Track if the user is in drawing mode
  const [polygons, setPolygons] = useState([]); // Store all drawn polygons
  const mapView = useRef(null);

  const drawFreeHand = () => {
    let drawing = true; // Local state to track drawing status

    // Change cursor to pointer while drawing
    mapView.current?.map_?.setOptions({ draggableCursor: "pointer" });

    const poly = new mapView.current.maps_.Polyline({
      clickable: false,
      map: mapView.current?.map_,
      strokeColor: "#f07605",
      strokeWeight: 3,
    });

    const mouseDownListener = mapView.current?.maps_?.event?.addListener(
      mapView.current?.map_,
      "mousedown",
      (e) => {
        drawing = true;
        poly?.getPath()?.push(e?.latLng);
      }
    );

    const mouseMoveListener = mapView.current.maps_.event.addListener(
      mapView.current.map_,
      "mousemove",
      (e) => {
        if (drawing) {
          poly?.getPath()?.push(e.latLng);
        }
      }
    );

    const mouseUpListener = mapView.current.maps_.event.addListener(
      mapView.current.map_,
      "mouseup",
      () => {
        if (drawing) {
          drawing = false;
          const path = poly?.getPath();
          poly?.setMap(null);

          const polygon = new mapView.current.maps_.Polygon({
            clickable: false,
            // fillColor: "#42A5F5",
            fillColor: "#ed8626",
            fillOpacity: 0.25,
            geodesic: true,
            map: mapView.current.map_,
            path,
            strokeColor: "#f07605",
            strokeWeight: 3,
          });
          setPolygons((prevPolygons) => [...prevPolygons, polygon]);
          // Reset the cursor to default after drawing
          mapView.current?.map_?.setOptions({ draggableCursor: null });
        }

        // Clean up event listeners after drawing is complete
        mapView.current?.maps_?.event?.removeListener(mouseDownListener);
        mapView.current?.maps_?.event?.removeListener(mouseMoveListener);
        mapView.current?.maps_?.event?.removeListener(mouseUpListener);
      }
    );
  };

  // Helper method to get the coordinates of a polygon
  const polyComplete = (poly) => {
    let bounds = "";
    const paths = poly?.getPaths();
    paths.forEach((path) => {
      const ar = path.getArray();
      for (let i = 0, l = ar.length; i < l; i++) {
        const lat = ar[i].lat();
        const lng = ar[i].lng();
        bounds += `${lng} ${lat},`;
      }
      if (ar[0]) {
        bounds += `${ar[0].lng()} ${ar[0].lat()}`;
      }
    });

    return bounds;
  };

  const clearShapes = () => {
    polygons?.forEach((shape) => shape?.setMap(null)); // Remove each shape from the map
    setPolygons([]); // Clear the shapes array
  };

  useEffect(() => {
    if (mapView.current && isDrawingMode) {
      const map = mapView.current?.map_;
      const maps = mapView.current?.maps_;

      maps?.event?.clearListeners(map, "mousedown"); // Ensure old listeners are cleared
      //handle draw free hand
      const mousedownListener = maps?.event?.addListener(
        map,
        "mousedown",
        drawFreeHand
      );
      return () => {
        maps.event?.removeListener(mousedownListener);
      };
    }
  }, [isDrawingMode]);

  // handle cancle button
  function handleCancel() {
    clearShapes();
    setDrawingMode(false);
    setPolygoneValues([]);
    setPolygons([]);
  }

  // handle drawing mode toggle
  const toggleDrawingMode = () => {
    if (isDrawingMode) {
      clearShapes(); // Clear existing polygons
    }
    setDrawingMode(!isDrawingMode); // Toggle drawing mode
  };

  // handle map options
  const getMapOptions = () => ({
    scrollwheel: true, // Allow zooming with the scroll wheel
    disableDoubleClickZoom: false, // Allow zooming with double-click
    fullscreenControl: false, // Disable the fullscreen control
    gestureHandling: isDrawingMode && "none", // Disable gestures
    clickableIcons: isDrawingMode ? false : true, // Disable interaction with points of interest
  });

  // handle Apply Polygone
  function handleApply() {
    const combinedValues = polygons
      ?.map((poly) => polyComplete(poly)) // Get all completed polygons
      .join(","); // Join all the results into a single string

    // Now set the state with just one value
    setPolygoneValues((prevPolygone) => [...prevPolygone, combinedValues]);
    setDrawingMode(false);
  }

  // Function to parse the polygon data
  const parseCoordinates = (data) => {
    try {
      const coordsString = JSON?.parse(data)[0]; // Parse the JSON string and get the first element
      return coordsString?.split(",")?.map((coord) => {
        const [lng, lat] = coord?.trim()?.split(" ")?.map(Number); // Split and convert to numbers
        return { lat, lng }; // Return the coordinate object
      });
    } catch (error) {
      console.error("Error parsing polygon data:", error);
      return [];
    }
  };

  // Function to add the polygon to the map
  const addPolygonToMap = (map, maps, coordinates) => {
    if (!coordinates || coordinates?.length === 0) {
      console.error("Invalid polygon coordinates.");
      return;
    }

    const polygon = new maps.Polygon({
      paths: coordinates,
      strokeColor: "#f07605",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#ed8626",
      fillOpacity: 0.35,
    });

    polygon?.setMap(map); // Attach the polygon to the map
    const combinedValues = [polygon]
      ?.map((poly) => polyComplete(poly)) // Get all completed polygons
      .join(",");
    setPolygoneValues([combinedValues]);
    setPolygons([polygon]);
  };

  // Function triggered when the API is loaded
  const handleApiLoaded = ({ map, maps }) => {
    if (isEditPropertyData && editPropertyData?.polygon) {
      const coordinates = parseCoordinates(editPropertyData?.polygon);
      addPolygonToMap(map, maps, coordinates);
    }
  };

  return (
    <div className="relative h-[300px] w-full ">
      {isValidArray(polygonValues) ? (
        <button
          className="absolute top-2 right-2 z-10 px-2 py-1.5 border border-orange-500 bg-orange-100 rounded-md font-medium"
          onClick={handleCancel}
        >
          Remove Polygon
        </button>
      ) : !isDrawingMode ? (
        // Draw Button
        <button
          className="absolute top-2 right-2 z-10 px-2 py-1.5 border border-orange-500 bg-orange-100 rounded-md font-medium"
          onClick={toggleDrawingMode}
        >
          Draw
        </button>
      ) : (
        <div
          className="w-full text-white h-auto sm:h-10 absolute top-0 z-10 flex flex-wrap sm:flex-nowrap justify-between items-center"
          style={{ backgroundColor: "rgba(68, 68, 68, 0.5)" }}
        >
          <label className="p-2 text-sm sm:text-base truncate">
            Draw a shape around the region(s) you would like to live in
          </label>
          <div className="space-x-3 px-4 flex flex-wrap sm:flex-nowrap justify-end">
            <button
              className="hover:bg-orange-300/[.60] hover:text-black px-2 h-10 text-sm sm:text-base"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="enabled:hover:bg-orange-300/[.60] enabled:hover:text-black px-2 h-10 disabled:opacity-70 text-sm sm:text-base"
              onClick={handleApply}
            >
              Apply
            </button>
          </div>
        </div>
      )}
      <GoogleMapReact
        ref={mapView}
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_API_KEY,
          libraries: ["drawing"],
        }}
        defaultCenter={{ lat: 34.4967, lng: -117.3489 }} // Mountain View Acres coordinates
        defaultZoom={10}
        yesIWantToUseGoogleMapApiInternals
        options={getMapOptions}
        onGoogleApiLoaded={handleApiLoaded}
      />
    </div>
  );
};

export default MapComponent;
